import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth_config.json";
import history from "./utils/history";
require('dotenv').config()


const onRedirectCallback = appState => {
  // Temporary Firefox workaround
  window.location.hash = window.location.hash; // eslint-disable-line no-self-assign
  console.log(process.env.REACT_APP_BASE);
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};
ReactDOM.render(  
  <Auth0Provider
    domain={config.domain}
    client_id={config.client_id}
    redirect_uri={process.env.REACT_APP_BASE}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>, document.getElementById('root'));

serviceWorker.unregister();
