import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Home from './views/Home';
import GameView from './views/GameView';
import OpenGameView from './views/OpenGameView';
import history from './utils/history';
import { useAuth0 } from './react-auth0-spa';
import Spinner from './components/Spinner';
import PrivateRoute from './components/PrivateRoute';
import NotFound from './views/NotFound';

function App() {
  const { loading } = useAuth0();

  if (loading) {
    return (
      <div className="App">
        <div className="d-flex flex-column h-100 align-item-center justify-content-center">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <Router history={history}>
      <div className="App">
        <div className="d-flex flex-column h-100 align-item-center justify-content-center">
          <Switch>
            <PrivateRoute exact path="/gameview" component={GameView} />
            <Route exact path="/open/:publicId" component={OpenGameView} />
            <PrivateRoute exact path="/:publicId" component={GameView} />
            <PrivateRoute exact path="/" component={GameView} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
