import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet';
import {Unity, useUnityContext } from 'react-unity-webgl';
// import gtag from 'ga-gtag'
import ReactGA4 from 'react-ga4';

var fileBrowser = require('./FileBrowser.html.js');

function WebGL(token, user, publicId, mode, mTurkId) {
	
  const { unityProvider, isLoaded, loadingProgression, sendMessage, addEventListener, removeEventListener, UNSAFE__unityInstance } = new useUnityContext({
		loaderUrl: process.env.REACT_APP_BASE + "webgl/Build/WebGL.loader.js",
		dataUrl: process.env.REACT_APP_BASE + "webgl/Build/WebGL.data.unityweb",
		frameworkUrl: process.env.REACT_APP_BASE + "webgl/Build/WebGL.framework.js.unityweb",
		codeUrl: process.env.REACT_APP_BASE + "webgl/Build/WebGL.wasm.unityweb"
  });
	
	const [state, setState] = useState({
	  mode: mode,
      simPublicId: publicId,
      token: token,
      progression: 0,
      loaded: false
	});
	
  useEffect(() => {
	addEventListener("RequestUserInfo", handleRequestUserInfo);
    setState({ progression: loadingProgression });

    // give access to the FileBrowser library
    window.gameInstance = UNSAFE__unityInstance;
	
    if (loadingProgression == 1 && !state.loaded) {
      setState({ loaded: true });
      ReactGA4.event('start_hs_webgl', {
        sim_id: state.simPublicId,
        mode: state.mode
      });
		}
		
	return () => {
      removeEventListener("RequestUserInfo", handleRequestUserInfo);
    };
  }, [UNSAFE__unityInstance]);

  const handleRequestUserInfo = useCallback(() => 
  {
	  sendMessage("ManagerScripts", "SetUserInfo",
	  JSON.stringify({
          ...user,
          token,
          simPublicId: publicId,
          mode: mode,
          mTurkId: mTurkId
        })
      );
  });

  /** Window resize */
  const [canvasDim, setCanvasDim] = useState({ width: window.innerWidth, height: window.innerHeight });
  const handleResize = (e) => {
    setCanvasDim({ width: window.innerWidth, height: window.innerHeight });
  }
  useLayoutEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

	return (
	  <span>
		  <Helmet>
			<script src='FileBrowserScript.js' type='text/javascript' />
		  </Helmet>
		  <div style={isLoaded ? {
        width: canvasDim.width,
        height: canvasDim.height,
        margin: "auto",
        overflow: "hidden"
        }: {display: 'none'}}>
		  <Unity className="unity" style={{"width": canvasDim.width, "height": canvasDim.height}}
			 unityProvider={unityProvider} />
		 </div>

		  <div style={isLoaded ? {display:'none'} : {
			display: "block",
			width: "100%",
			margin: "auto",
			top: "48%",
			position: "absolute",
			fontSize: 25,
			textAlign: "center"
		  } }>
			Loading... { Math.round(loadingProgression * 100) } %
		  </div>

		  <span dangerouslySetInnerHTML={{ __html: fileBrowser }} />
		</span>
	);
}
export default WebGL;
