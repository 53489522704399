import React, { Fragment, useState, useEffect } from 'react';
import { browserName, browserVersion, osName, osVersion } from 'react-device-detect';
import '../App.css';
import WebGL from '../components/WebGL';
import queryString from 'query-string'

class OpenGameView extends React.Component {
  constructor(props) {
    super(props);

    // Get URL parameters
    this.publicId = props.match.params.publicId === undefined ?
      '' : props.match.params.publicId;
    var queryParams = queryString.parse(props.location.search);
    this.mode = queryParams.mode;

    this.state = {
      mTurkId: "",
      user: null,
      token: null,

      // visual
      missingModeErrorVisible: this.mode == null,
      mTurkFormErrorVisible: false,
      mTurkFormVisible: this.mode == "mturk" ? true : false,
      webGLVisible: false
    };

    this.handleMTurkIdChange = this.handleMTurkIdChange.bind(this);
    this.handleMTurkForm = this.handleMTurkForm.bind(this);
    this.sendDebugLogs = this.sendDebugLogs.bind(this);
  }


  async sendDebugLogs() {

    // get status
    var startTime = new Date().getTime();
    const healthResponse = await fetch('https://hmdev.siminsights.com/public/health', { method: 'GET' });
    var latencyMs = new Date().getTime() - startTime;
    var healthStatus = healthResponse.ok;

    // send log data
    var logData = {
      appName: 'hypermock-webapp-shell',
      applicationType: 'Build',
      deviceInfo: {
        deviceModel: browserName + " " + browserVersion,
        deviceOS: osName + " " + osVersion,
        deviceType: 'Web'
      },
      event: 'webapp_shell_loaded',
      logType: 'event',
      recordType: 'hypermock-event',
      server: 'Production',
      userId: 'mturk-' + this.state.mTurkId,
      timestamp_client: new Date().getTime(),
      pingSuccess: healthStatus,
      pingLatencyMs: latencyMs,
      sessionId: 'none'
    };

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'bcjymWOBNo71Di5gTrXc87b3zAvwNKxd5VSOUQdV'
      },
      body: JSON.stringify(logData)
    };

    fetch('https://7logvxruvg.execute-api.us-west-1.amazonaws.com/prod/record', requestOptions);

  }

  handleMTurkIdChange(event) {
    this.setState({ mTurkId: event.target.value });
  }

  handleMTurkForm(event) {
    event.preventDefault();

    // validation
    this.setState({ mTurkId: this.state.mTurkId.trim() });

    if (this.state.mTurkId == null || this.state.mTurkId.length <= 10) {
      this.state.mTurkFormErrorVisible = true;
    } else {
      this.setState({
        mTurkFormVisible: false,
        webGLVisible: true
      });
    }

    this.sendDebugLogs();
  }

  render() {
    return (
      <Fragment>
        { this.state.missingModeErrorVisible &&
          <span>The mode is missing</span>
        }


        { this.state.mTurkFormVisible &&
        <form onSubmit={this.handleMTurkForm}>
          <label>
            <div style={{ display: this.state.mTurkFormErrorVisible ? 'block' : 'none' }}>Invalid MTurk ID!</div>
            MTurk ID:  &nbsp;
            <input type="text" value={this.state.mTurkId} onChange={this.handleMTurkIdChange}/>
          </label>
          <input type="submit" value="Submit"/>
        </form>
        }

        { this.state.webGLVisible &&
        <WebGL token={this.token}
              user={this.state.user}
              publicId={this.publicId}
              mode={this.mode}
              mTurkId={this.state.mTurkId}/>
        }
      </Fragment>
    );
  }
};

export default OpenGameView;
