module.exports = `
<style>
    #fb_popup_background.highlight {
        border: 3px dashed #ccc;
        border-radius: 26px;
        border-color: aquamarine;
    }
</style>

<div id="fileBrowserPopup" style="display: none; margin: -0.4%; position: absolute; width: 100%; height: 100%; left: 0; top: 0; bottom: 0; right: 0;">
    <img src="FileBrowser/2x2.png" style="position: absolute; width: 100%; height: 100%;" />

    <div id="fb_popup">
        <img id="fb_popup_background" src="FileBrowser/popup_background.png" style="position: fixed; top: 0;  right: 0; bottom: 0; left: 0; margin: auto; width: 502.5px; height: 206px;" />
        <div>
            <img src="FileBrowser/popup_header.png" style="position: fixed; top: -146px;  right: 0; bottom: 0; left: 0; margin: auto; width: 502.5px; height: 60.5px;" />
            <strong id="fb_popup_header_title" style="position: fixed; top: -110px;  right: -20px; bottom: 0; left: 0; margin: auto; width: 500px; height: 58px; color: white;">
                File Browser
            </strong>
        </div>
        <div id="open_file_form">
            <strong id="fb_popup_description_title" style="position: fixed; top: 0px;  right: 0px; bottom: 0; left: 0; margin: auto; width: 500px; height: 58px; text-align: center; color: black;">
                Select file to load or use drag & drop
            </strong>

            <label for="fileToUpload">
                <img src="FileBrowser/select_button.png" style="position: fixed; top: 0px;  right: 250px; bottom: -80px; left: 0; margin: auto; width: 193.5px; height: 41px;" />
                <strong id="fb_popup_select_button_title" style="position: fixed; top: 0px;  right: 250px; bottom: -100px; left: 0; margin: auto; width: 193.5px; height: 41px; text-align: center; color: white;">
                    Select
                </strong>
            </label>
            <input type="file" multiple name="fileToUpload" id="fileToUpload" style="width: 0px; height: 0px;" onchange="fbLoadFiles(event.target.files);return false;" />

            <label for="closePopup">
                <img src="FileBrowser/close_button.png" style="position: fixed; top: 0px;  right: -250px; bottom: -80px; left: 0; margin: auto; width: 193.5px; height: 41px;" />
                <strong id="fb_popup_close_button_title" style="position: fixed; top: 0px;  right: -245px; bottom: -100px; left: 0; margin: auto; width: 193.5px; height: 41px; text-align: center; color: white;">
                    Close
                </strong>
            </label>
            <input type="button" name="closePopup" id="closePopup" style="width: 0px; height: 0px;" onclick="requestCloseFileBrowserForOpen()" />
        </div>
    </div>
</div>`
