import React, { Fragment, useState, useEffect } from 'react';
import '../App.css';
import WebGL from '../components/WebGL';
import { useAuth0 } from '../react-auth0-spa';
import Spinner from '../components/Spinner';
import queryString from 'query-string'
// import gtag from 'ga-gtag';
import ReactGA from 'react-ga4' 

const GameView = (props) => {
  const { loading, getTokenSilently, user } = useAuth0();
  const [token, setToken] = useState('');
  useEffect(() => {
    async function fetchData() {
      var token = await getTokenSilently({ audience: 'https://login.siminsights.com' }).catch(e => { console.log(e) });
      setToken(token);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (token != null && token != '') {
      fetch('https://hmdev.siminsights.com/user/info', {
            method: 'GET',
            headers: new Headers({ 'Authorization': 'Bearer ' + token })
          })
          .then(response => response.json())
          .then(data => {
            window.dataLayer.push({'user_id': '' + data.userId});
            ReactGA.initialize('G-CWSB8XFW4X', { 'user_id' : 'hsuser-' + data.userId });
          })
      }
  });

  const publicId =
    props.match.params.publicId === undefined
      ? ''
      : props.match.params.publicId;
  console.log(publicId);
  if (loading || !user || token == '') {
    return <Spinner />;
  }
  const queryParams = queryString.parse(props.location.search)
  console.log(queryParams.mode)
  return (
    <Fragment>
      <WebGL token={token} user={user} publicId={publicId} mode={queryParams.mode} mTurkId={props.mTurkId}/>
      {/* <Button style={{margin:"auto" }} color="primary" size="lg" onClick={() => { logout({returnTo:process.env.REACT_APP_BASE}) }}>Sign Out</Button> */}
    </Fragment>
  );
};
export default GameView;
